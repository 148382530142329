import axios from 'axios'
import useSWR, { useSWRConfig } from 'swr'
import { useQuery } from 'context/QueryProvider'

interface SwrOptions {
  onSuccess?: (data: any) => void
  onError?: (error: any) => void
}

export default function useSWRCustom(url: string | null, options?: SwrOptions) {
  const { client } = useQuery()

  const fetcher = async (URL: string) => {
    const resp = await axios.get(URL, { headers: client.headers })
    return resp?.data
  }

  const { data, error, isValidating, mutate } = useSWR(url, fetcher, options)

  return {
    data: data?.['hydra:member'] ?? data,
    error,
    isValidating,
    mutate,
    total: (data?.['hydra:totalItems'] ?? data?.nbResults) ?? null,
  }
}

export {
  useSWRConfig,
}
