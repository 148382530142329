import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'
import { useCollapse } from 'hooks/collapse'
import Button from 'components/UI/Button'

interface SectionProps {
  title?: string
  titleExtra?: React.ReactNode
  options?: React.ReactElement|null
  collapse?: boolean
  children: React.ReactNode
  withBackBtn?: boolean|string
  className?: string
  titleClassName?: string
  contentClassName?: string
}

export default function Section({
  title = '',
  titleExtra = null,
  options = null,
  collapse = false,
  children,
  withBackBtn = false,
  className = '',
  titleClassName = '',
  contentClassName = '',
}: SectionProps) {
  const { Icon, open } = useCollapse()
  const navigate = useNavigate()

  const back = typeof withBackBtn === 'string'
    ? () => navigate(withBackBtn)
    : () => navigate(-1)

  const collapseCss = collapse
    ? open
      ? 'opacity-100 mt-2'
      : 'max-h-0 opacity-0 mt-0'
    : !!title ? 'mt-4' : 'mt-2'

  return (
    <div
      className={`
        bg-white border border-grey-300 rounded-xl px-8 py-6
        flex flex-col w-full relative
        ${className || ''}
      `}
    >
      {withBackBtn && (
        <Button
          className="
            rounded-full border-none drop-shadow-md
            !w-10 h-10 bg-gray-200 text-[--primary]
            absolute top-8 -left-5
          "
          onClick={back}
          icon={<LeftOutlined className="text-sm relative top-[1px] -left-[1px]" />}
        />
      )}
      <div className="flex justify-between items-center w-full h-fit overflow-hidden">
        <div
          className={`
            text-2xl text-gray-600 font-bold h-fit line-clamp-1 flex items-center ${titleClassName || ''}
          `}
        >
          {title}{titleExtra}
        </div>
        {collapse ? <Icon className="ml-2" /> : null}
        <div className="grow" />
        <div className="flex items-center">{options}</div>
      </div>
      <div
        className={`transition-all duration-300 ${collapseCss} ${contentClassName || ''}`}>
        {children}
      </div>
    </div>
  )
}
