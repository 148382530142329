import * as React from 'react'
import { Checkbox, Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import type { SortOrder, SorterResult, TablePaginationConfig } from 'antd/es/table/interface'
import { DownOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'

interface Props {
  defaultPageSize?: number
  defaultOrderBy?: string
  defaultOrderDir?: SortOrder
  selectAll?: boolean
}

// @TODO Write tests
export const useTableState = ({
  defaultPageSize = 10,
  defaultOrderBy,
  defaultOrderDir,
  selectAll,
}: Props) => {
  const [selection, setSelection] = React.useState<React.Key[]>([])
  const [allSelected, setAllSelected] = React.useState<boolean>(false)
  const [current, setCurrent] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(defaultPageSize)
  const [sortedInfo, setSortedInfo] = React.useState<SorterResult<any>>({
    field: defaultOrderBy,
    columnKey: defaultOrderBy,
    column: undefined,
    order: defaultOrderDir || 'ascend',
  })
  const [total, setTotal] = React.useState(0)

  const numberOfPages = React.useMemo(() => Math.ceil(total / pageSize), [total, pageSize])

  const { i18n } = useI18n()

  const items: MenuProps['items'] = [{
    key: 'all',
    label: i18n('table.select-all'),
  }, {
    key: 'none',
    label: i18n('table.select-none'),
  }]

  React.useEffect(() => {
    if (current > numberOfPages) setCurrent(1)
  }, [current, numberOfPages])

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'all':
        setAllSelected(true)
        setSelection([])
        break
      case 'none':
        setAllSelected(false)
        setSelection([])
        break
      default:
      // pass
    }
  }

  const handleChange = React.useCallback((pagination: TablePaginationConfig, _: any, sorter: any) => {
    if (pagination?.current) setCurrent(pagination.current)
    if (pagination?.pageSize) setPageSize(pagination.pageSize)
    setSortedInfo(sorter as SorterResult<any>)
  }, [setPageSize, setCurrent, setSortedInfo])

  const handleSelection = {
    selectedRowKeys: Object.values(selection).flat() || [],
    preserveSelectedRowKeys: true,
    onChange: (newKeys: React.Key[]) => {
      setSelection(newKeys)
      setAllSelected(false)
    },
    renderCell: (checked: boolean, _record: any, _index: any, originalNode: any) => (
      <Checkbox
        checked={allSelected || checked}
        onChange={originalNode?.props?.onChange}
      />
    ),
    ...(selectAll ? {
      columnTitle: (originalNode: any) => (
        <div className="flex justify-between">
          <Checkbox
            checked={allSelected || originalNode?.props?.checked}
            indeterminate={selection.length || originalNode?.props?.indeterminate}
            onChange={originalNode?.props?.onChange}
          />
          <Dropdown menu={{ items, onClick }}>
            <DownOutlined className="cursor-pointer" />
          </Dropdown>
        </div>
      ),
    } : {}),
  }

  const resetSelection = React.useCallback(() => setSelection([]), [])

  const sortUrl = React.useMemo(() => {
    if (!sortedInfo.order) return ''
    // 'ascend' -> 'asc'
    const order = sortedInfo.order.replace('end', '')
    /**
     * Basic sorting:
     * {
     *   dataIndex: ['cost'],
     *   sorter: true,
     *   sortOrder: sortedInfo.field === 'cost' ? sortedInfo.order : null,
     * }
     *
     * But if you have an array as 'dataIndex' you can use column key for sorting:
     * {
     *   dataIndex: ['user', 'name'],
     *   key: 'user.lastName',
     *   sorter: true,
     *   sortOrder: sortedInfo.columnKey === 'user.lastName' ? sortedInfo.order : null,
     * }
     */
    const field = sortedInfo.columnKey || sortedInfo.field
    return `&order[${field}]=${order}`
  }, [sortedInfo.columnKey, sortedInfo.field, sortedInfo.order])

  return {
    current,
    setCurrent,
    pageSize,
    selection,
    allSelected,
    sortedInfo,
    sortUrl,
    handleChange,
    handleSelection,
    resetSelection,
    setTotalOfItems: setTotal,
    total,
  }
}
